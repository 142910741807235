import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// style
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../reactcss/Theme';
// style ends

const Basiccheck = (props) => {
    return (
        <ThemeProvider theme={Theme}>
        <FormControl
        error={props.Error}
        >
                {props.Label ? <FormLabel component="legend">{props.Label}</FormLabel> : ""}
                <Checkbox
                    defaultChecked={props.DefaultChecked}
                    disabled={props.Disabled}
                    checked={props.Checked}
                    size={props.Size}
                    color={props.Color}
                />
                {props.Helpertext ? <FormHelperText>You can display an error</FormHelperText> : ""}
                </FormControl>
        </ThemeProvider>
    );
}

export default Basiccheck;
