import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import { Grid } from '@mui/material';
import Pagetitle from '../components/texts/Pagetitle';
import Pagediv from '../components/section/Pagediv';
import Dashcard from '../components/dashboard/Dashcard';
import Formfieldhead from '../components/texts/Formfieldhead';
import { MdDocumentScanner, MdFireTruck } from "react-icons/md";

import Linechart from '../components/charts/Linechart';
import Barchart from '../components/charts/Barchart';
import Pichart from '../components/charts/Pichart';


const Dashboard = () => {
    const { setnavoptions, login } = useContext(Context);
    console.log(login)
    useEffect(() => {
        setnavoptions('dashboard');
    }, []);
    return (
        <div>
            <Pagetitle title="Dashboard" />

            <div className='page-section'>
                <div className='dashcard-container'>
                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Dashcard Url="/bill" Count={36} Name="Total Bill" Color="dashred" Icon={<MdDocumentScanner className='dashicons' />} />
                        <Dashcard Url="#" Count={58} Name="Transports" Color="dashyellow" Icon={<MdFireTruck className='dashicons' />} />
                        <Dashcard Url="#" Count={58} Name="Transports" Color="dashgreen" Icon={<MdFireTruck className='dashicons' />} />
                        <Dashcard Url="#" Count={58} Name="Transports" Color="dashpurple" Icon={<MdFireTruck className='dashicons' />} />
                        <Dashcard Url="#" Count={58} Name="Transports" Color="dashblue" Icon={<MdFireTruck className='dashicons' />} />
                        <Dashcard Url="#" Count={58} Name="Transports" Color="dashlightblue" Icon={<MdFireTruck className='dashicons' />} />
                    </Grid>

                    {/* charts */}
                    <Pagediv />
                    <Pagetitle title="Charts" />

                    <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12} md={6}>
                            <Formfieldhead title="Line Chart" />
                            <div className='charts'>
                                <Linechart X={[1, 2, 3, 4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]} Y={[2, 5.5, 2, 99, 1.5, 5,9,5,3,4,7,8,6,5,4,1,2,5,6,2,4,8,9,6,4,7,180,5,6,1]} Color="#557A46" />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Formfieldhead title="Bar Chart" />
                            <div className='charts'>
                                <Barchart X={['bar A', 'bar B', 'bar C', "bar D", "bar E", "bar F"]} Y={[2, 5, 4, 6, 8, 4]} Color="green" />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Formfieldhead title="Pi Chart" />
                            <div className='charts'>
                                <Pichart Data={
                                    [
                                        { id: 0, value: 10, label: 'series A', color:"#EF9595" },
                                        { id: 1, value: 18, label: 'series B', color:"#D3E4CD" },
                                        { id: 2, value: 20, label: 'series C', color:"#557A46" },
                                    ]
                                } />
                            </div>
                        </Grid>
                    </Grid>
                    {/* charts */}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
