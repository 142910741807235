import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import Pagetitle from '../components/texts/Pagetitle';
import Formfieldhead from '../components/texts/Formfieldhead';
import Basicbutton from '../components/button/Basicbutton';
import Iconbutton from '../components/button/Iconbutton';
import Pagediv from '../components/section/Pagediv';
import { MdDelete, MdSend } from "react-icons/md";
import Basicloader from '../components/Loaders/Basicloader';
import { Stack, Grid } from '@mui/material';

const Buttons = () => {
    const { setnavoptions } = useContext(Context);
    useEffect(() => {
        setnavoptions('buttons');//this is for active sidebar
    });
    return (
        <>
            <Pagetitle title="Select Options" />
            <Formfieldhead title="Basic Button" />

            <Grid container rowSpacing={{ xs: 3, sm: 3, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Text" Variant="text" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Contained" Variant="contained" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Outlined" Variant="outlined" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Disabled" Variant="contained" Disabled="disabled" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Link" Variant="contained" Link="#" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Secondary" color="secondary" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Success" Variant="contained" Color="success" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Error" Variant="outlined" Color="error" />
                </Grid>
            </Grid>

            <Pagediv />

            <Formfieldhead title="Button Sizes" />
            <Grid container rowSpacing={{ xs: 3, sm: 3, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Small" Variant="contained" Size="small" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Medium" Variant="contained" Size="medium" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Basicbutton Name="Large" Variant="contained" Size="large" />
                </Grid>
            </Grid>


            <Pagediv />
            <Formfieldhead title="Button with icon + Stack" />
            <Stack spacing={4} direction="row">
                <Basicbutton Name="Delete" Variant="outlined" Size="small" Starticon={<MdDelete />} />
                <Basicbutton Name="Send" Variant="contained" Size="small" Endicon={<MdSend />} />
            </Stack>

            <Pagediv />
            <Formfieldhead title="Icon Buttons + Stack" />
            <Stack spacing={4} direction="row">
                <Basicbutton Name="Delete" Variant="outlined" Size="small" Starticon={<MdDelete />} />
                <Basicbutton Name="Send" Variant="contained" Size="small" Endicon={<MdSend />} />
            </Stack>

            <Pagediv />
            <Formfieldhead title="Btn Icon + Stack" />
            <Stack spacing={4} direction="row">
                <Iconbutton Name="Delete" Icon={<MdDelete />} Size="small" />
                <Iconbutton Name="Delete" Icon={<MdDelete />} Disabled="disabled" />
            </Stack>

            <Pagediv />
            <Formfieldhead title="Loading Buttons" />
            <Grid container rowSpacing={{ xs: 3, sm: 3, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item xs={4} md={2}>
                    <Basicbutton Name={<Basicloader Color="#b2b2b2" WH="18"  />}  Variant="contained" />
                </Grid>

            </Grid>

        </>
    );
}

export default Buttons;
