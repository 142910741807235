import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Time from 'react-live-clock';
import Context from '../../context/Context';

import { AiOutlineLogout, AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import Clock from '../../public/image/icons/clock.svg';
import Drop from '../../public/image/icons/drop.svg';
import Admin from '../../public/image/admin.png';

export default function Header() {
  const { dropop, setdropop, setlogin } = useContext(Context);

  const logout = ()=>{
    localStorage.setItem("login", "not");
    window.location.href = "/";
  }

  const dropops = () => {
    if (dropop === false) {
      setdropop(true);
    } else {
      setdropop(false);
    }
  }

  return (
    <>
      {/* menu */}
      <div className={dropop === false ? 'head-drop-op head-drop-op-t' : 'head-drop-op'}>
        <Link to={"/error"} className='head-drop-ops flex-align cp'>
          <AiOutlineUser className='head-drop-icon' />
          <p className='head-drop-text nav-text'>Profile</p>
        </Link>
        
        <Link to={"/dashboard"} className='head-drop-ops flex-align cp'>
          <AiOutlineSetting className='head-drop-icon' />
          <p className='head-drop-text nav-text'>Settings</p>
        </Link>

        <div onClick={logout}>
          <div className='flex-align cp logout-btn cp'>
            <AiOutlineLogout className='head-drop-icon' />
            <p className='head-drop-text nav-text'>Logout</p>
        </div>
        </div>
      </div>
      {/* menu ends */}

      <header className='header flex-align space-between'>
        <div className='left-head'></div>
        <div className='right-head flex-align'>

          <div className='flex-align'>
            <img className='clock' src={Clock} alt='clock' />
            <p className='nav-text clock-text'><Time format={'HH:mm:ss'} ticking={true} /></p>
          </div>

          <div className='nav-il admin flex-align'>
            <p className='nav-text admin-name'>Admin</p>
            <div className='admin-pro-div cp'><img className='admin-profile' src={Admin} alt='admin' /></div>
          </div>

          <img className='head-drop nav-il cp' src={Drop} alt='drop' onClick={dropops} />

        </div>
      </header>
    </>
  )
}
