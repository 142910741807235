import React from 'react';

const Formfieldhead = (props) => {
    return (
        <>
          <p className='form-field-head'>{props.title}</p>  
        </>
    );
}

export default Formfieldhead;
