import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import Pagetitle from '../components/texts/Pagetitle';
import Formfieldhead from '../components/texts/Formfieldhead';
import Basicradio from "../components/radio/Basicradios";
import Pagediv from '../components/section/Pagediv';
import Basiccheck from '../components/check/Basiccheck';


const Radios = () => {
    const { setnavoptions } = useContext(Context);
    useEffect(() => {
        setnavoptions('radios');//this is for active sidebar
    });
    return (
        <>
           <Pagetitle title="Radio & check Buttons" />
            <Formfieldhead title="Basic Radio" />
            <Basicradio Name="General" Options={[{"title":"Option 1"},{"title":"Option 2"},{"title":"Option 3"},{"title":"A Option 1"},{"title":"The Option 1", "disabled":"disabled"}]} Row="row" Helpertext="Helpertext" />
        
            <Pagediv/>
            <Formfieldhead title="Basic Check" />

            <Basiccheck DefaultChecked="defaultChecked" />
            <Basiccheck />
            <Basiccheck Size="small"/>
            <Basiccheck Disabled="desabled" />
            <Basiccheck Checked="checked" />
            <Basiccheck Checked="checked" Color="secondary"/>
            <Basiccheck Checked="checked" Color="success"/>
            <Basiccheck Color="success" Size="large"/>
            <Basiccheck Checked="checked" Color="default"/>

            <Pagediv/>
            <Formfieldhead title="With Helper Text Check + Error" />
            <Basiccheck DefaultChecked="defaultChecked" Color="success" Helpertext="Helper Text" Error="error"/>

            <Pagediv/>
            <Formfieldhead title="With Label Text Check + Error" />
            <Basiccheck DefaultChecked="defaultChecked" Color="success" Label="Label Text" Error="error"/>    
        </>
    );
}

export default Radios;
