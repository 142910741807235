import React from 'react';
import { InputLabel, MenuItem, ListSubheader, FormControl, Select } from '@mui/material';

const Groupselectbox = (props) => {
    return (
        <>
            <FormControl
                fullWidth={props.FullWidth}
                size={props.Size}
                variant={props.Variant} 
            >
                <InputLabel htmlFor="grouped-native-select">{props.Name}</InputLabel>
                <Select native defaultValue="" id="grouped-native-select" label={props.Name}>
                    <option aria-label="None" value="" />
                    {
                        props.Options.map((data) => (
                            <optgroup label={data.category}>
                                {
                                    data.options.map((ops)=>(
                                        <option value={ops}>{ops}</option>
                                    ))
                                }
                            </optgroup>
                        ))
                    }
                </Select>
            </FormControl>
        </>
    );
}

export default Groupselectbox;
