// style
import { createTheme } from '@mui/material/styles';
import Var from '../../css/Variables';

const Theme = createTheme({
    palette: {
        primary: {
            main: Var.tmc,
        },
        secondary: {
            main: Var.tmc_bg,
        },
    },
});
// style ends

export default Theme;