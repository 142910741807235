import React, { useEffect, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import Context from '../../../../context/Context';

import Pagetitle from '../../../components/texts/Pagetitle';
import Formfieldhead from '../../../components/texts/Formfieldhead';
import Textbox from '../../../components/input/Textbox';
import Selectbox from '../../../components/input/Selectbox';
import Basicbutton from '../../../components/button/Basicbutton';
import Pagediv from '../../../components/section/Pagediv';
import CustomTable from '../../../components/table/CustomTable';

const Classes = () => {
  // table data
  const data = [
    { 'totalitems': 38 },
    ['Name', 'Surname', 'Phone', 'Email', 'DOB'],
    [
        {
            "Name": "John",
            "Surname": "Doe",
            "Phone": "+1123456789",
            "Email": "john.doe@example.com",
            "DOB": "1990-05-15"
        },
        {
            "Name": "Emily",
            "Surname": "Johnson",
            "Phone": "+1444444444",
            "Email": "emily.johnson@example.com",
            "DOB": "1988-12-03"
        },
        {
            "Name": "Michael",
            "Surname": "Williams",
            "Phone": "+1567890123",
            "Email": "michael.williams@example.com",
            "DOB": "1979-08-25"
        },
        {
            "Name": "Emma",
            "Surname": "Brown",
            "Phone": "+1789012345",
            "Email": "emma.brown@example.com",
            "DOB": "1983-04-10"
        },
        {
            "Name": "James",
            "Surname": "Jones",
            "Phone": "+1901234567",
            "Email": "james.jones@example.com",
            "DOB": "1987-10-29"
        },
        {
            "Name": "Olivia",
            "Surname": "Garcia",
            "Phone": "+1123456789",
            "Email": "olivia.garcia@example.com",
            "DOB": "1992-06-17"
        },
        {
            "Name": "William",
            "Surname": "Martinez",
            "Phone": "+1444444444",
            "Email": "william.martinez@example.com",
            "DOB": "1980-03-05"
        },
        {
            "Name": "Ava",
            "Surname": "Lopez",
            "Phone": "+1567890123",
            "Email": "ava.lopez@example.com",
            "DOB": "1995-01-12"
        },
        {
            "Name": "Alexander",
            "Surname": "Hernandez",
            "Phone": "+1789012345",
            "Email": "alexander.hernandez@example.com",
            "DOB": "1976-11-08"
        },
        {
            "Name": "Sophia",
            "Surname": "Smith",
            "Phone": "+1901234567",
            "Email": "sophia.smith@example.com",
            "DOB": "1989-07-22"
        },
        {
            "Name": "Ethan",
            "Surname": "Taylor",
            "Phone": "+1123456789",
            "Email": "ethan.taylor@example.com",
            "DOB": "1982-02-18"
        },
        {
            "Name": "Isabella",
            "Surname": "Brown",
            "Phone": "+1444444444",
            "Email": "isabella.brown@example.com",
            "DOB": "1993-09-14"
        },
        {
            "Name": "Mason",
            "Surname": "Martinez",
            "Phone": "+1567890123",
            "Email": "mason.martinez@example.com",
            "DOB": "1977-12-01"
        },
        {
            "Name": "Charlotte",
            "Surname": "Garcia",
            "Phone": "+1789012345",
            "Email": "charlotte.garcia@example.com",
            "DOB": "1986-08-07"
        },
        {
            "Name": "Logan",
            "Surname": "Lopez",
            "Phone": "+1901234567",
            "Email": "logan.lopez@example.com",
            "DOB": "1998-04-23"
        },
        {
            "Name": "Amelia",
            "Surname": "Hernandez",
            "Phone": "+1123456789",
            "Email": "amelia.hernandez@example.com",
            "DOB": "1984-01-30"
        },
        {
            "Name": "Lucas",
            "Surname": "Jones",
            "Phone": "+1444444444",
            "Email": "lucas.jones@example.com",
            "DOB": "1996-11-26"
        },
        {
            "Name": "Mia",
            "Surname": "Smith",
            "Phone": "+1567890123",
            "Email": "mia.smith@example.com",
            "DOB": "1981-06-19"
        },
        {
            "Name": "Benjamin",
            "Surname": "Taylor",
            "Phone": "+1789012345",
            "Email": "benjamin.taylor@example.com",
            "DOB": "1991-03-10"
        },
        {
            "Name": "Harper",
            "Surname": "Williams",
            "Phone": "+1901234567",
            "Email": "harper.williams@example.com",
            "DOB": "1978-12-05"
        },
        {
            "Name": "Elijah",
            "Surname": "Doe",
            "Phone": "+1123456789",
            "Email": "elijah.doe@example.com",
            "DOB": "1987-08-02"
        },
        {
            "Name": "Evelyn",
            "Surname": "Johnson",
            "Phone": "+1444444444",
            "Email": "evelyn.johnson@example.com",
            "DOB": "1989-02-28"
        },
        {
            "Name": "Michael",
            "Surname": "Williams",
            "Phone": "+1567890123",
            "Email": "michael.williams@example.com",
            "DOB": "1994-10-20"
        },
        {
            "Name": "Abigail",
            "Surname": "Brown",
            "Phone": "+1789012345",
            "Email": "abigail.brown@example.com",
            "DOB": "1974-07-15"
        },
        {
            "Name": "Aiden",
            "Surname": "Garcia",
            "Phone": "+1901234567",
            "Email": "aiden.garcia@example.com",
            "DOB": "1997-05-11"
        },
        {
            "Name": "Sofia",
            "Surname": "Lopez",
            "Phone": "+1123456789",
            "Email": "sofia.lopez@example.com",
            "DOB": "1980-09-08"
        },
        {
            "Name": "Jackson",
            "Surname": "Hernandez",
            "Phone": "+1444444444",
            "Email": "jackson.hernandez@example.com",
            "DOB": "1983-04-04"
        },
        {
            "Name": "Madison",
            "Surname": "Jones",
            "Phone": "+1567890123",
            "Email": "madison.jones@example.com",
            "DOB": "1992-12-27"
        },
        {
            "Name": "Ella",
            "Surname": "Lopez",
            "Phone": "+1789012345",
            "Email": "ella.lopez@example.com",
            "DOB": "1986-11-21"
        },
        {
            "Name": "Noah",
            "Surname": "Martinez",
            "Phone": "+1901234567",
            "Email": "noah.martinez@example.com",
            "DOB": "1985-02-16"
        },
        {
            "Name": "Avery",
            "Surname": "Smith",
            "Phone": "+1123456789",
            "Email": "avery.smith@example.com",
            "DOB": "1996-08-13"
        },
        {
            "Name": "Liam",
            "Surname": "Taylor",
            "Phone": "+1444444444",
            "Email": "liam.taylor@example.com",
            "DOB": "1981-03-09"
        },
        {
            "Name": "Grace",
            "Surname": "Williams",
            "Phone": "+1567890123",
            "Email": "grace.williams@example.com",
            "DOB": "1979-09-04"
        },
        {
            "Name": "Ethan",
            "Surname": "Brown",
            "Phone": "+1789012345",
            "Email": "ethan.brown@example.com",
            "DOB": "1998-05-01"
        },
        {
            "Name": "Chloe",
            "Surname": "Garcia",
            "Phone": "+1901234567",
            "Email": "chloe.garcia@example.com",
            "DOB": "1984-12-26"
        },
        {
            "Name": "Liam",
            "Surname": "Johnson",
            "Phone": "+1123456789",
            "Email": "liam.johnson@example.com",
            "DOB": "1993-11-20"
        },
        {
            "Name": "Ella",
            "Surname": "Martinez",
            "Phone": "+1444444444",
            "Email": "ella.martinez@example.com",
            "DOB": "1987-07-17"
        },
        {
            "Name": "Avery",
            "Surname": "Smith",
            "Phone": "+1567890123",
            "Email": "avery.smith@example.com",
            "DOB": "1975-06-12"
        }
    ]
]

  const { setnavoptions } = useContext(Context);
  const [classdata, setclassdata] = useState("");

  // first API calling
  const getData = async () => {
    let headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json"
    }

    try {
      // existing code for fetching data
      let response = await fetch("http://192.168.0.186:5000/api/class", {
        method: "GET",
        headers: headersList
      });

      let data = await response.json();
      setclassdata(data.classes[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    setnavoptions('masters'); //this is for active sidebar
    getData();
  }, []);

  return (
    <div>
      <Pagetitle title="Classes" />
      <Formfieldhead title="Add And Update Class" />
      <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} md={3}>
          <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Class Name" Variant="outlined" Value="" Color="Success" />
        </Grid>
        <Grid item xs={6} md={3}>
          <Selectbox Name="Status" Type="error" Helpertext="" Options={['Active', 'Inactive']} FullWidth Size="small" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Basicbutton Name="Submit" Variant="contained" />
        </Grid>
      </Grid>

      <Pagediv />

      <CustomTable data={data} title="Table Title"  />

      <Pagediv />
    </div>
  );
}

export default Classes;
