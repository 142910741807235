import React, {useState} from "react";
import Context from "./Context";
const maincontext = (props)=>{
  const vwidth = window.innerWidth;
  const vheight = window.innerHeight;

    // port
    const [port, setport] = useState("http://192.168.0.172:5000");
    // header some
    const [dropop, setdropop] = useState(false);
    const [sidetoggle, setsidetoggle] = useState(true);
    const [navoptions, setnavoptions] = useState('');
    const [navdrop, setnavdrop] = useState('');
    const [nameshow, setnameshow] = useState(true);
    // header some ends

    // Login
    const [login, setlogin] = useState(true);
    // Login ends

return(
    <Context.Provider value={{dropop, setdropop, sidetoggle, setsidetoggle, navoptions, setnavoptions, navdrop, setnavdrop, vwidth, vheight, nameshow, setnameshow, login, setlogin, port, setport}}>
      {props.children}  
    </Context.Provider>
)
}
export default maincontext;