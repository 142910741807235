import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { OutlinedInput, InputLabel, MenuItem, FormControl, Select, Checkbox, ListItemText, Box, Chip } from '@mui/material';

const Multiselectbox = (props) => {
  // 
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  // 
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  return (
    <>
      <FormControl
        fullWidth={props.FullWidth}
        size={props.Size}
        variant={props.Variant} 
      >
        <InputLabel id="demo-multiple-name-label">{props.Name}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id={props.Type === "chip" ? "select-multiple-chip" : "" } label={props.Name} />}
          renderValue={props.Type === "chip" ? 
          (selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )
          : (selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {/* here Map is workin with condition */}
          {props.Type === "basic" || props.Type === "chip" ? props.Options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))
          :
          props.Type === "check" ?
          props.Options.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))
          :
          ""
          }
          {/* here Map is workin with condition ends */}
        </Select>
      </FormControl>
    </>
  );
}

export default Multiselectbox;
