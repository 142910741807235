import React from 'react';
import { TailSpin } from 'react-loader-spinner'

const Basicloader = (props) => {
    return (
        <>
            <TailSpin
                width={props.WH}
                height={props.WH}
                color={props.Color ? props.Color : "#b2b2b2"}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </>
    );
}

export default Basicloader;
