import React, { useState } from 'react';

import { Select, FormControl, MenuItem, InputLabel, FormHelperText } from '@mui/material';

const Selectbox = (props) => {
    const [Basic, setBasic] = useState('');
    const values = props.Options;
    return (
        <div>
            <FormControl 
            fullWidth={props.FullWidth}
            size={props.Size} 
            disabled={props.Disabled}
            error={props.Error}
            variant={props.Variant} 
            >
                <InputLabel id="demo-simple-select-label">{props.Name}</InputLabel>
                <Select
                    labelId={props.Type === "basic" ? "demo-simple-select-label" : props.Type === "error" ? "demo-simple-select-error-label":""}
                    id={props.Type === "basic" ? "demo-simple-select" : props.Type === "error" ? "demo-simple-select-error":""}
                    value={Basic}
                    label={props.Name}
                    onChange={(event) => { setBasic(event.target.value) }}
                    renderValue={props.Error === true ? (value) => `${props.Errorval} ${value}`: ``}
                    inputProps={props.InputProps}
                >
                    <MenuItem disabled value="choose">choose option</MenuItem>
                    <MenuItem value="">None</MenuItem>
                    {values.map((value) => (
                        <MenuItem value={value} key={value}>{value}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{props.Helpertext}</FormHelperText>
            </FormControl>
        </div>
    );
}

export default Selectbox;
