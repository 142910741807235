import React from 'react';
import { TextField } from '@mui/material';
import Css from '../../reactcss/Style';


const Textbox = (props) => {
  const handleTextFieldChange = (e)=>{
    props.setValue(e.target.value);
    props.validation(false);
    props.Emsg("")
  }
    return (
        <>
          <TextField 
          style={Css.TextField}
          className={props.UiClass} 
          type={props.Type} 
          label={props.Label} 
          variant={props.Variant} 
          defaultValue={props.Value}
          disabled={props.Disabled} 
          required={props.Required}
          InputProps={{
            readOnly: props.Readonly,
          }}
          error={props.Error}
          helperText={props.Helpertext}
          size={props.Size}
          multiline={props.Multiline}
          maxRows={props.MaxRows}
          rows={props.Rows}
          placeholder={props.Placeholder}
        //   color={props.Color}
        onChange={handleTextFieldChange}
          />  
        </>
    );
}

export default Textbox;

// variants
// outlined, filled, standard

// classes
// input-outlined, input-filled, input-standard

// use "disabled" to disable field