import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

// style
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../reactcss/Theme';
// style ends

const Linechart = (props) => {
    return (
        <ThemeProvider theme={Theme}>
            <LineChart
                xAxis={[{ data: props.X }]}
                series={[
                    {
                        data: props.Y,
                        color: props.Color
                    },
                ]}
                height={300}
            />
        </ThemeProvider>
    );
}

export default Linechart;
