import React from 'react';

// css
import './TextHead.css'

// components
import ButtonFilled from '../buttons/ButtonFilled';
import ButtonFilledW from '../buttons/ButtonFilledW';

// images
import Sparkle from '../../../../public/web/icons/sparkle.svg';

const TextHeadCenter = (props) => {
    console.log(props.textcolor)
    return (
        <div className={props.align === "center" ? 'center' : props.align === 'left' ? "tleft" : "tright"}>
            <p className='title-text'>{props.title}</p>
            <div className='headtext-div'>
                <h1 className={props.align === 'center' ? 'main-text mr-center' : 'main-text'}>
                    {props.icon === "S" ? <img className='elet' src={Sparkle} alt="sparkle" /> : ""}
                    <span style={props.textcolor === undefined ? {color:""} : {color:props.textcolor}}>{props.header}</span>
                </h1>
            </div>
            <p className={props.align === "center" ? 'head-para mr-center' : 'head-para'}><span style={props.textcolor === undefined ? {color:""} : {color:props.textcolor}}>{props.para}</span></p>
            {props.button === undefined ? "" : props.buttont === "A"? <ButtonFilled value={props.button} link={props.link} align={props.align} /> : <ButtonFilledW value={props.button} link={props.link} align={props.align} />}
        </div>
    );
}

export default TextHeadCenter;
