import React from 'react';
import { Autocomplete, TextField, GroupHeader, GroupItems } from '@mui/material';

const Searchandselectcat = (props) => {
    const options = props.Options.map((option) => {
        const firstLetter = option.title[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    return (
        <>
            <Autocomplete
                fullWidth={props.FullWidth}
                size={props.Size}
                variant={props.Variant} 
                id="grouped-demo"
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} label={props.Name} />}
            />
        </>
    );
}
export default Searchandselectcat;
