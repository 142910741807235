import React from 'react';

// components
import TextHeadCenter from '../components/TextHeads/TextHeadCenter';

// images
import CRBG from '../../../public/web/icons/curbg.png';

const DownloadSec = () => {
    return (
        <div className='downloadsec'>
            <div className='cur-out'>
                <img className='cur-out-img' src={CRBG} alt='crbg' />
            </div>
            <div className="downloadsec-texthead">
                <TextHeadCenter
                    textcolor="#ffffff"
                    title=""
                    header="Download the app & Join us in this E-learning revolution !"
                    para="Lorem ipsum dolor sit amet consectetur. Nullam purus id tempus non. Etiam accumsan pretium sagittis elit in nunc. "
                    button="Download Now"
                    buttont="B"
                    link=""
                    align="center" />
            </div>
        </div>
    );
}

export default DownloadSec;
