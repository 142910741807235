import React from 'react';

// css
import './buttons.css';

const ButtonOutline = (props) => {
    return (
        <div className='flex'>
            <div className={props.bg === "tr" ? 'btn-outline btn-outline-tr' : 'btn-outline'}>
                {props.value}
            </div>
        </div>
    );
}

export default ButtonOutline;
