import React from 'react';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

// style
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../reactcss/Theme';
// style ends


const Basicbutton = (props) => {
    return (
        <ThemeProvider theme={Theme}>
            <Button
                disabled={props.Disabled}
                variant={props.Variant}
                href={props.Link}
                color={props.Color}
                size={props.Size}
                startIcon={props.Starticon}
                endIcon={props.Endicon}
            >
                {props.Name}
            </Button>
        </ThemeProvider>
    );
}

export default Basicbutton;
