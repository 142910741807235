import React from 'react';
import { Grid, Item } from '@mui/material';

// css
import './zsection.css';

// components
import TextHeadCenter from '../TextHeads/TextHeadCenter';



const Rsection = (props) => {
    return (
        <section className='zsection'>
            <Grid container spacing={2} columns={16}>
            <Grid item xs={16} sm={8} md={8}>
                    <div>
                        <img className='image100' src={props.img} alt={props.alt} />
                    </div>
                </Grid>
                <Grid className='all-center' item xs={16} sm={8} md={8}>
                    <div>
                        <TextHeadCenter title={props.title} header={props.header} para={props.para} button={props.button} align={props.center} />
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default Rsection;
