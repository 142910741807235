import React from 'react';
import RoutesContext from './Main';

const App = () => {
    return (
        <>
            <RoutesContext />
        </>
    );
}

export default App;
