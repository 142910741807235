import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const Barchart = (props) => {
    return (
        <>
            <BarChart
                xAxis={[
                    {
                        id: 'barCategories',
                        data: props.X,
                        scaleType: 'band',
                    },
                ]}
                series={[
                    {
                        data: props.Y,
                        color: props.Color 
                    },
                ]}
                height={300}
            />
        </>
    );
}

export default Barchart;
