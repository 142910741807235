import React from 'react';

import { FaArrowRight } from "react-icons/fa6";

// css
import './buttons.css';

const ButtonFilled = (props) => {
    return (
        <div className='all-center'>
            <div className='button-re'>
                <p>{props.value}</p>
                <div className='arrow-re all-center'>
                    <FaArrowRight className='arrow-re-icon' />
                </div>
            </div>
        </div>
    );
}

export default ButtonFilled;
