import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import Pagetitle from '../components/texts/Pagetitle';
import Formfieldhead from '../components/texts/Formfieldhead';
import { Grid } from '@mui/material';

import Basicloader from '../components/Loaders/Basicloader';

const Loaders = () => {
    const { setnavoptions } = useContext(Context);
    useEffect(() => {
        setnavoptions('loaders');//this is for active sidebar
    });
    return (
        <>
            <Pagetitle title="Loaders" />
            <Grid container rowSpacing={{ xs: 3, sm: 3, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item xs={4} md={2}>
                    <Basicloader />
                </Grid>

            </Grid>
            
        </>
    );
}

export default Loaders;
