import * as React from 'react';
import { isBandScale } from '../internals/isBandScale';
export function getTickNumber(params) {
  const {
    tickMaxStep,
    tickMinStep,
    tickNumber,
    range,
    domain
  } = params;
  const maxTicks = tickMinStep === undefined ? 999 : Math.floor(Math.abs(domain[1] - domain[0]) / tickMinStep);
  const minTicks = tickMaxStep === undefined ? 2 : Math.ceil(Math.abs(domain[1] - domain[0]) / tickMaxStep);
  const defaultizedTickNumber = tickNumber != null ? tickNumber : Math.floor(Math.abs(range[1] - range[0]) / 50);
  return Math.min(maxTicks, Math.max(minTicks, defaultizedTickNumber));
}
function useTicks(options) {
  const {
    scale,
    tickNumber,
    valueFormatter
  } = options;
  return React.useMemo(() => {
    // band scale
    if (isBandScale(scale)) {
      const domain = scale.domain();
      if (scale.bandwidth() > 0) {
        // scale type = 'band'
        return [...domain.map(value => {
          var _valueFormatter;
          return {
            formattedValue: (_valueFormatter = valueFormatter == null ? void 0 : valueFormatter(value)) != null ? _valueFormatter : value,
            offset: scale(value) - (scale.step() - scale.bandwidth()) / 2,
            labelOffset: scale.step() / 2
          };
        }), {
          formattedValue: undefined,
          offset: scale.range()[1],
          labelOffset: 0
        }];
      }

      // scale type = 'point'
      return domain.map(value => {
        var _valueFormatter2;
        return {
          formattedValue: (_valueFormatter2 = valueFormatter == null ? void 0 : valueFormatter(value)) != null ? _valueFormatter2 : value,
          offset: scale(value),
          labelOffset: 0
        };
      });
    }
    return scale.ticks(tickNumber).map(value => {
      var _valueFormatter3;
      return {
        formattedValue: (_valueFormatter3 = valueFormatter == null ? void 0 : valueFormatter(value)) != null ? _valueFormatter3 : scale.tickFormat(tickNumber)(value),
        offset: scale(value),
        labelOffset: 0
      };
    });
  }, [tickNumber, scale, valueFormatter]);
}
export default useTicks;