import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import Pagetitle from '../components/texts/Pagetitle';
import Formfieldhead from '../components/texts/Formfieldhead';
import Pagediv from '../components/section/Pagediv';

// tables
import Basictable from '../components/table/Basictable';
import Datatable from '../components/table/Datatable';
import Mdatatable from '../components/table/Mdatatable';
// tables ends

const Tables = () => {
    // basic table data
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }
    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 5.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];
    // basic table data ends
    const { setnavoptions } = useContext(Context);
    useEffect(() => {
        setnavoptions('tables');//this is for active sidebar
    });
    return (
        <>
            <Pagetitle title="Table" />
            <Formfieldhead title="Basic Table" />
            <Basictable Rows={rows}  />

            <Pagediv/>
            <Pagetitle title="Datatable" />
            <Formfieldhead title="With Material UI" />
            <Mdatatable />
            
            <Pagediv/>
            <Pagetitle title="Data Table" />
            <Formfieldhead title="With All Functionalites" />
            <Datatable />

        </>
    );
}

export default Tables;
