import React from 'react';

// css
import './buttons.css';

const ButtonFilledW = (props) => {
    // console.log(props.link);
    return (
        <div className='flex'>
            <div className='btn-Filled btn-FilledW'>
                {props.value}
            </div>
        </div>
    );
}

export default ButtonFilledW;
