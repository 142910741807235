import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const Searchandselect = (props) => {
    return (
        <>
            <Autocomplete
                fullWidth={props.FullWidth}
                size={props.Size}
                variant={props.Variant} 
                disablePortal
                id="combo-box-demo"
                options={props.Options}
                renderInput={(params) => <TextField {...params} label={props.Name} />}
            />
        </>
    );
}

export default Searchandselect;
