import React, { useContext, useState } from 'react';
import { MdLogin } from "react-icons/md";
import Context from '../../context/Context';
import {useNavigate} from 'react-router-dom';

import Textbox from '../components/input/Textbox';
import Basicbutton from '../components/button/Basicbutton';
import Basiccheck from '../components/check/Basiccheck';

const Forgot = () => {
    const { port } = useContext(Context);
    const navigate = useNavigate();
    // inputs
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    // input ends

    //validition 
    const [vEmail, setvEmail] = useState(false);
    const [VEM, setVEM] = useState("");
    const validdata = () => {
        function validateEmail(email) {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return pattern.test(email);
        }
        if (validateEmail(Email)) {
            setvEmail(false);
            setVEM("");
            OnLogin();
        } else {
            setvEmail(true);
            setVEM("Email is invalid");
        }
    }
    // validition ends

    const logingin = () => {
        localStorage.setItem("login", "yes");
        window.location.href = "/";
    }

    // Login submit
    const OnLogin = async () => {
        let headersList = {
            "Accept": "*/*",
            "Content-Type": "application/json"
        }

        let bodyContent = JSON.stringify({
            "email": Email,
            "password": Password
        });

        let response = await fetch(`${port}/api/user/login`, {
            method: "POST",
            body: bodyContent,
            headers: headersList
        });

        let data = await response.json();
        console.log(data);

        // loging in
        if (response.status === 200) {
            // storing on session
            let sessionStorage = window.sessionStorage;
            sessionStorage.setItem("AntToken", data.token);
            logingin();
        }
        if (response.status === 404) {
            if (data.message === "IU") {
                setvEmail(true);
                setVEM("Incurrect Username");
            }
        }
    }



    return (
        <>
            {/* <div className='all-center vw vh'>
                <div className='login-card'>
                    <div className='login-head-box'>
                        <p className='login-head center'>Forgot Password</p>
                        <p className='login-para center'>Please enter your Username to get OTP</p>
                    </div>

                    <div className='login-textbox'>
                        <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Username" Variant="outlined" Value={Email} setValue={setEmail} Color="Success" Error={vEmail ? true : false} Helpertext={VEM} validation={setvEmail} Emsg={setVEM} />
                    </div>

                    <div className='flex-align space-between rmp-box'>
                    </div>

                    <div className='center login-btn' onClick={validdata}>
                        <Basicbutton Name="Get OTP" Variant="contained" Size="large" />
                    </div>
                </div>
            </div> */}

            {/* <div className='all-center vw vh'>
                <div className='login-card'>
                    <div className='login-head-box'>
                        <p className='login-head center'>OTP</p>
                        <p className='login-para center'>Please check your Email and enter OTP</p>
                    </div>

                    <div className='login-textbox'>
                        <Textbox Size="small" UiClass="input-outlined" Type="number" Label="Enter OTP" Variant="outlined" Value={Email} setValue={setEmail} Color="Success" Error={vEmail ? true : false} Helpertext={VEM} validation={setvEmail} Emsg={setVEM} />
                    </div>

                    <div className='flex-align space-between rmp-box'>  
                    </div>

                    <div className='center login-btn' onClick={validdata}>
                        <Basicbutton Name="Submit" Variant="contained" Size="large" />
                    </div>
                </div>
            </div> */}

            <div className='all-center vw vh'>
                <div className='login-card'>
                    <div className='login-head-box'>
                        <p className='login-head center'>Reset Password</p>
                        <p className='login-para center'>Enter your new Password</p>
                    </div>

                    <div className='login-textbox'>
                        <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Enter New Password" Variant="outlined" Value={Email} setValue={setEmail} Color="Success" Error={vEmail ? true : false} Helpertext={VEM} validation={setvEmail} Emsg={setVEM} />
                    </div>

                    <div className='login-textbox'>
                        <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Confirm Password" Variant="outlined" Value={Email} setValue={setEmail} Color="Success" Error={vEmail ? true : false} Helpertext={VEM} validation={setvEmail} Emsg={setVEM} />
                    </div>

                    <div className='flex-align space-between rmp-box'>  
                    </div>

                    <div className='center login-btn' onClick={()=>{navigate("/Admin");}}>
                        <Basicbutton Name="Submit" Variant="contained" Size="large" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Forgot;
