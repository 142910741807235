import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Dashcard = (props) => {
    const navigate = useNavigate();
    const routeChange = (url) =>{ 
        navigate(url);
      }
    return (
        <>
            <Grid item xs={6} md={3}>
                <div className='dashcard flex-align cp' onClick={()=>{routeChange(props.Url)}}>
                    <div className={`dashiconbox ${props.Color} all-center`}>
                        {props.Icon}
                    </div>
                    <div className='dashcountbox'>
                        <p className='dashcount'>{props.Count}</p>
                        <p className='dashname'>{props.Name}</p>
                    </div>
                </div>
            </Grid>
        </>
    );
}

export default Dashcard;
