import React, { useEffect, useState } from 'react';
import $ from 'jquery';


import Textbox from '../input/Textbox';
import Basicbutton from '../button/Basicbutton';
import { MdFilterAlt } from "react-icons/md";
import { MdArrowCircleLeft, MdArrowCircleRight, MdArrowDownward, MdOutlineSync } from "react-icons/md";
import { ExportCSV } from './ExportCSV';


const CustomTable = (props) => {

    // filter options
    const [filter, setfilter] = useState(0);
    const Oselected = (e, o) => {
        setfilter(o);
    }
    // filter options

    // pages
    const [pagedisableL, setpagedisableL] = useState(true);
    const [pagedisableR, setpagedisableR] = useState(false);

    const [tp, settp] = useState(0);
    const [Page, setPage] = useState(1);
    const PageP = () => {
        if (Page > 1) {
            setPage(Page - 1)
            setpagedisableR(false);
        }
        if (Page === 2) {
            setpagedisableL(true);
        }
    }
    const PageN = () => {
        if (Page < tp) {
            setPage(Page + 1);
            setpagedisableL(false);
        }
        if (Page === tp - 1) {
            setpagedisableR(true);
        }
    }

    // sorting data
    const [SortVal, setSortVal] = useState(null);
    const Filterreset = () => {
        setfilter(0);
        setSortVal(null);
        $('.toggle-checkbox').prop('checked', false);
    }
    const Sorting = (h) => {
        setSortVal(h);
        $('.toggle-checkbox').prop('checked', false);
        $(`.sort${h}`).prop('checked', true);
    }

    useEffect(() => {
        let tp = Math.ceil(props.data[0].totalitems / 10);
        settp(tp);
    }, []);

    return (
        <div className='cusTableDiv custom-div-bg'>
            <div className='tableHead flex-align space-between'>
                <h3 className='tableTitle'>{props.title}</h3>
                <div className='tableActions flex-align direction-right'>
                    <Basicbutton Name="Search" Variant="contained" />
                    <div className='hrmr'></div>
                    <div className="tablesearch"><Textbox Size="small" UiClass="input-outlined" Type="text" Label="Search" Variant="outlined" Value="" Color="Success" /></div>
                    <div className='hrmr'></div>

                    <div className='filterbox'>
                        <MdFilterAlt />
                        {filter === 0 ? <></> : <div className='selectedNotif all-center'>{filter}</div>}
                        <div className='filteroptions'>
                            <div className={filter === 1 ? 'optiondiv optionactive' : 'optiondiv'} onClick={() => { Oselected("option 1", 1) }}><p className='filteroption'>option 1</p></div>
                            <div className={filter === 2 ? 'optiondiv optionactive' : 'optiondiv'} onClick={() => { Oselected("option 2", 2) }}><p className='filteroption'>option 2</p></div>
                            <div className={filter === 3 ? 'optiondiv optionactive' : 'optiondiv'} onClick={() => { Oselected("option 3", 3) }}><p className='filteroption'>option 3</p></div>
                        </div>
                    </div>
                    <div className='filterbox'>
                        <MdOutlineSync className='filterreset' onClick={() => { Filterreset() }} />
                    </div>
                </div>
            </div>

            <div className='cusTable'>

                <table className='Table w-100'>
                    <thead>
                        <tr className='TheadRow'>
                            <th>SNO</th>
                            {props.data[1].map((h, index) => (
                                <th key={index}><div className='flex-align space-between'>{h} <div className='relative'><input type="checkbox" id="toggle" className={`toggle-checkbox sort${h}`} onClick={() => { Sorting(h) }} /><MdArrowDownward className={`SortingArrow`} /></div></div></th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {props.data[2].slice(10*Page-10, 10*Page).map((d, index) => (
                            <tr key={index} className='TRows'>
                                <td>{(index + 1)+(10*(Page-1))}</td>
                                {props.data[1].map((v, index) => (
                                    <td key={index}>
                                        {d[v]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>


                </table>

            </div>
            <div className='TableFooter flex-align space-between'>

                <div className='Tfooter-left'>
                    {/* <div className='tableIconbtn all-center cp'><MdDownloadForOffline /></div> */}
                    <ExportCSV csvData={props.data[2]} fileName={props.title} />
                </div>

                <div className='Tfooter-right flex-align'>
                    <p><span className='pageCount'>Pages :</span> {Page} of {tp}</p>
                    <div className='TableArrows flex-align'>
                        <div className={pagedisableL === true ? 'TableArrow all-center tableArrowDisable' : 'TableArrow all-center'} onClick={() => { PageP() }}><MdArrowCircleLeft /></div>
                        <div className={pagedisableR === true ? 'TableArrow all-center tableArrowDisable' : 'TableArrow all-center'} onClick={() => { PageN() }}><MdArrowCircleRight /></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomTable;
