import React, { useEffect } from 'react';

import Basicloader from '../components/Loaders/Basicloader';

const redirect = ()=>{
    setTimeout(() => {
        window.location.href = "Home";
    }, 1000);
}

const PreLoader = () => {
    useEffect(()=>{
        redirect();
    },[])
    return (
        <div className='vw vh all-center'>
            <Basicloader />
        </div>
    );
}

export default PreLoader;
