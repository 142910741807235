import React, { useContext, useEffect } from 'react';

import HeaderWeb from './includes/HeaderWeb';
import FooterWeb from './includes/FooterWeb';
import Loader from '../pages/PreLoader';
import Context from '../../context/Context';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles


// css
import '../../css/web/style.css';
import '../../css/web/section.css';

// components
import TextHeadCenter from './components/TextHeads/TextHeadCenter';
import ButtonRe from './components/buttons/ButtonRe';
import Lsection from './components/zsections/Lsection';
import Rsection from './components/zsections/Rsection';
import DownloadSec from './sections/DownloadSec';

// images
import DownloadBanner from '../../public/web/wall/download.webp';
import Qna from '../../public/web/wall/qna.webp';
import Books from '../../public/web/wall/books.webp';
import inClass from '../../public/web/wall/class.webp';
import IOS from '../../public/web/appstore.png';
import PlayS from '../../public/web/playstore.png';
import Rul from '../../public/web/wall/rules.webp';
import Lang from '../../public/web/wall/lang.webp';
import Test from '../../public/web/wall/test.webp';
import Rewards from '../../public/web/wall/rewards.webp';
import Students from '../../public/web/icons/students.png';
import curv from '../../public/web/bg/curv.png';

// elements
import Hat from '../../public/web/elements/hat.png';
import Bulb from '../../public/web/elements/bulb.png';
import Bookss from '../../public/web/elements/books.png';
import Hat2 from '../../public/web/elements/hatR.png';
import Gloab from '../../public/web/elements/glob.png';
import Search from '../../public/web/elements/search.png';
import Cup from '../../public/web/elements/cup.png';
import Hand from '../../public/web/elements/hand.png';

// bg elements
import pp from '../../public/web/elements/pp.png';
import pp2 from '../../public/web/elements/pp2.png';
import ppp from '../../public/web/elements/ppp.png';
import plp from '../../public/web/elements/plp.png';
import bb from '../../public/web/elements/bb.png';
import bb2 from '../../public/web/elements/bb2.png';
import bb3 from '../../public/web/elements/bb3.png';

const Home = () => {
    const {login} = useContext(Context);
    useEffect(() => {
        document.title = 'Aanant Shiksha';
        AOS.init();
    }, [])
    return (
        <>
            <HeaderWeb />
            <section className='body-section relative' data-aos="fade-up">
                <img className='elements hat' src={Hat} alt='element' />
                <img className='elements pp' src={pp} alt='pp' />
                <img className='elements bb' src={bb} alt='bb' />
                <Lsection
                    header="Revolutionizing the E-learning space with Anannt shiksha"
                    para="Lorem ipsum dolor sit amet consectetur. Nunc vitae consectetur risus libero eget nullam scelerisque condimentum. Diam imperdiet a eget nunc mauris fusce semper in."
                    center="left"
                    img={Rul}
                    alt="Rural"
                    sec="1"
                    button="Download"
                    buttont="A"
                />
            </section>

            <section className='relative bg-div'>
                <img className='bg-curv' src={curv} alt='curv' data-aos="fade-up" />
                <img className='elements bulb' src={Bulb} alt='bulb' data-aos="fade-up" />
                <img className='elements ppp' src={ppp} alt='ppp' data-aos="fade-up" />

                <section className='body-section-2'>
                    <div className='hi-para' data-aos="fade-up">
                        <p>Anannt Shiksha, designed exclusively for rural students <img className='text-student' src={Students} alt="students" /> brings quality education to your fingertips, ensuring accessible and empowering e-learning opportunities. Anannt Shiksha bridges education gaps for rural students through digital learning.</p>
                    </div>
                    <div className='center' data-aos="fade-up"><img className='banner100' src={inClass} alt='annant shiksha download' /></div>
                </section>
            </section>

            <section className='body-section-2'>

                <div className='relative' data-aos="fade-up">
                    <img className='elements books' src={Bookss} alt='books' />
                    <Lsection
                        title="Wide range of courses"
                        header="Empowering rural students with free course materials"
                        para="Lorem ipsum dolor sit amet consectetur. Nunc vitae consectetur risus libero eget nullam scelerisque condimentum. Diam imperdiet a eget nunc mauris fusce semper in."
                        center="left"
                        img={Rul}
                        alt="Rural"
                    />
                </div>

                <div className='relative' data-aos="fade-up">
                    <img className='elements hatR' src={Hat2} alt='HatR' />
                    <img className='elements pp2' src={pp2} alt='pp2' />
                    <Rsection
                        title="Available in Hindi & English"
                        header="Switch between Hindi & English easily"
                        para="Lorem ipsum dolor sit amet consectetur. Nunc vitae consectetur risus libero eget nullam scelerisque condimentum. "
                        center="left"
                        img={Lang}
                        alt="Languadeg"
                    />
                </div>

                <div className='relative' data-aos="fade-up">
                    <img className='elements gloab' src={Gloab} alt='gloab' />
                    <img className='elements bb3' src={bb3} alt='bb3' />
                    <Lsection
                        title="Guidance for Students"
                        header="Career counselling & Personality test"
                        para="Lorem ipsum dolor sit amet consectetur. Nunc vitae consectetur risus libero eget nullam scelerisque condimentum. "
                        center="left"
                        img={Test}
                        alt="Test"
                    />
                </div>

                <div data-aos="fade-up">
                    <TextHeadCenter
                        title="Regular tests"
                        header="QnA series & Quarterly test"
                        para="Lorem ipsum dolor sit amet consectetur. Nullam purus id tempus non. Etiam accumsan pretium sagittis elit in nunc. "
                        align="center" />
                </div>

                <div className='center relative' data-aos="fade-up">
                    <img className='elements search' src={Search} alt='Search' />
                    <img className='elements plp' src={plp} alt='plp' />
                    <img className='banner80' src={Qna} alt='Qna' />
                </div>

                <div className='relative' data-aos="fade-up">
                    <img className='elements pp22' src={pp2} alt='pp2' />
                    <Lsection
                        title="Rewards system"
                        header="Rewards for students for completing courses"
                        para="Lorem ipsum dolor sit amet consectetur. Nunc vitae consectetur risus libero eget nullam scelerisque condimentum. "
                        center="left"
                        img={Rewards}
                        alt="rewards"
                    />
                </div>

                <div className='relative'>
                    <img className='elements cup' src={Cup} alt='cup' data-aos="fade-up" />
                    <img className='elements bb2' src={bb2} alt='bb2' data-aos="fade-up" />
                    <div data-aos="fade-up">
                        <TextHeadCenter title="Study material in 2 languages" header="Text books available in both languages Hindi & English" para="Lorem ipsum dolor sit amet consectetur. Nullam purus id tempus non. Etiam accumsan pretium sagittis elit in nunc. " align="center" />
                    </div>
                    <div className='center' data-aos="fade-up"><img className='banner80' src={Books} alt='Qna' /></div>
                </div>

                <div className='relative' data-aos="fade-up">
                    <img className='elements hand' src={Hand} alt="hand" />
                    <img className='elements ppp2' src={ppp} alt='ppp' />
                    <DownloadSec />
                </div>
            </section>

            <FooterWeb />
        </>
    );
}

export default Home;
