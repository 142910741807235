import React from 'react';

// public imports
import Logo from '../../../public/web/logo.png';

// css
import '../../../css/web/footer.css';

const FooterWeb = () => {
    return (
        <div className='footerdiv all-center'>
            <div className='footerin center'>
                <img className='footer-logo' src={Logo} alt='Annant Shiksha' />
                <p className='footer-para'>Anannt Shiksha, designed exclusively for rural students brings quality education to your fingertips, ensuring accessible and empowering e-learning opportunities. </p>
                <div className='flex-align space-between'>
                    <div className='footer-link'>Privacy Policy</div>
                    <div className='footer-link'>Terms & Conditions</div>
                    <div className='footer-link'>Download App</div>
                </div>
                <div className='copyright center'>
                <p>&#169; Anannatshiksha.com</p>
            </div>
            </div>
        </div>
    );
}

export default FooterWeb;
