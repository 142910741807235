import React, { useContext } from 'react';
import Context from '../../context/Context';

import Basicbutton from '../components/button/Basicbutton';

const Error = () => {
    const { login } = useContext(Context); 
    const Reload = ()=>{
        if(login === true){
            window.open('/dashboard',"_self");
        }else{
            window.open('/',"_self");
        }
    }
    return (
        <>
            <div className={login === false ? 'all-center vh vw' : 'all-center mr-err-t'}>
                <div className='error-box'>
                    <div className='login-head-box'>
                        <p className='login-head center'>Page Not Found</p>
                        <p className='login-para center'>Oops! It seems you've taken a wrong turn. The page you're looking for isn't here.<br/>Let's get you back on track!</p>
                    </div>
                    <div className='center login-btn' onClick={Reload}>
                    <Basicbutton Name="Reload" Variant="contained" Size="large" />
                    </div>
                </div>
            </div>
        </>
    );
}
export default Error;
