import React, { useContext } from 'react';
import Context from '../context/Context';
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Sidebar from "./includes/Sidebar";
import Header from "./includes/Header";
import Footer from "./includes/Footer";


export default function Body() {
  const {setdropop, sidetoggle} = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/dashboard");
    }
  }, []);
  return (
    <div className='main-body'>
      <Sidebar />
      <div className={sidetoggle === true ? 'body' : "body body-t"}>
        <Header />
        <div className='body-Con-area' onClick={()=>{setdropop(false)}}>
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  )
}
