import React from 'react'

export default function Footer() {
  return (
    <>
    <footer className='footer'>
      Footer
    </footer>
    </>
  )
}
