import React from 'react';

const Pagetitle = (props) => {
    return (
        <>
           <h3 className='pagetitle'>{props.title}</h3> 
        </>
    );
}

export default Pagetitle;
