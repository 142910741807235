import React, { useContext } from 'react'
import Context from '../../context/Context';

import Logo from '../../public/image/logo.png';
import Aleft from '../../public/image/icons/arrow-left.svg';
import Sidebarlinks from './imports/Sidebarlinks';

export default function Sidebar() {
  const {setdropop, sidetoggle, setsidetoggle, setnavdrop, nameshow, setnameshow} = useContext(Context);

  const sidet = () => {
    if (sidetoggle === true) {
      if(setnavdrop !== ''){
        setnameshow(false);
        setnavdrop(false);
      }
      setsidetoggle(false);
    } else {
      setTimeout(() => {
        setnameshow(true);
      }, 300);
      setsidetoggle(true);
    }
  }

  return (
    <React.Fragment>
      <div className={sidetoggle === true ? 'sidebar' : 'sidebar sidebar-t'} onClick={()=>{setdropop(false)}}>
        <div className='sidebar-head flex-align relative'>
          <img className='logo' src={Logo} alt='logo'/>
          <p className={nameshow === true ? 'comp-name' : 'hide'}>Company Name</p>
          {/* sidebar toggle btn */}
          <div className={sidetoggle === true ? 'sidebar-toggle all-center' : 'sidebar-toggle all-center sidebar-toggle-180'} onClick={sidet}>
          <img className='st-btn' src={Aleft} alt='toggle' />
          </div>
          {/* sidebar toggle btn */}
        </div>

        {/* sidebar links */}
          <Sidebarlinks />
        {/* sidebar links ends */}

      </div>
    </React.Fragment>
  )
}
