import React from 'react';

// css
import './buttons.css';

const ButtonFilled = (props) => {
    // console.log(props.link);
    return (
        <div className='flex'>
            <div className={props.align === 'left' ? "btn-Filled left": props.align === 'right'? 'btn-Filled right' : 'btn-Filled'}>
                {props.value}
            </div>
        </div>
    );
}

export default ButtonFilled;
