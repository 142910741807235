import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Pagetitle from '../components/texts/Pagetitle';
import Textbox from '../components/input/Textbox';
import Formfieldhead from '../components/texts/Formfieldhead';

const Bill = () => {
    const { setnavoptions } = useContext(Context);
    useEffect(() => {
        setnavoptions('bill');
    }, []);
    return (
        <div>
            <Pagetitle title="Input Fields" />
            <Formfieldhead title="Form Field" />
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="First Name" Variant="outlined" Value="Sangam" Color="Success" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Last Name" Variant="outlined" Disabled />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="number" Label="Mobile" Variant="outlined" Color="warning" Required />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="email" Label="Email" Variant="outlined" Color="secondary" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="password" Label="Password" Variant="outlined" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Read Only" Variant="outlined" Value="Read Only" Readonly={true}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox UiClass="input-outlined" Type="search" Label="Search" Variant="outlined" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Helper Text" Variant="outlined" Helpertext="Error Occure" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Helper Text" Variant="outlined" Helpertext="Error Occure" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Error" Variant="outlined" Value="Error" Error Helpertext="Error Occure" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Multiline" Variant="outlined" Value="Multiline" Multiline MaxRows={4} />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Placeholder" Variant="outlined" Placeholder="Placeholder"  />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Textbox Size="small" UiClass="input-outlined" Type="text" Label="Rows Fixed" Variant="outlined" Rows={4} Multiline />
                </Grid>
            </Grid>
            {/* <Button variant="contained">Hello world</Button> */}
        </div>
    );
}

export default Bill;
