import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';    
import FormHelperText from '@mui/material/FormHelperText';

// style
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../reactcss/Theme';
// style ends


const Radios = (props) => {
    return (
        <ThemeProvider theme={Theme}>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">{props.Name}</FormLabel>
                <RadioGroup
                    row={props.Row}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name={props.Name}
                >
                    {props.Options.map((data)=>(
                        <FormControlLabel value={data.title} control={<Radio />} label={data.title} disabled={data.disabled} />
                    ))}
                </RadioGroup>
                <FormHelperText>{props.Helpertext}</FormHelperText>
            </FormControl>
        </ThemeProvider>
    );
}

export default Radios;
