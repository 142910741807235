// import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import SubRoutes from './Routes';
import MainContext from '../context/MainContext';

const Jsx = () => {
    return (
        <BrowserRouter>
            <MainContext>
                <SubRoutes />
            </MainContext>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Jsx />);

// this export default is nothing but used to reduse an error while building (not found default export on this file)
export default Jsx;
