import React from 'react';

import IconButton from '@mui/material/IconButton';

const Iconbutton = (props) => {
    return (
        <>
            <IconButton
            aria-label={props.Name}
            disabled={props.Disabled}
            size={props.Size}
            >
                {props.Icon}
            </IconButton>
        </>
    );
}

export default Iconbutton;
