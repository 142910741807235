import React, { useContext, useEffect } from 'react';
import Context from '../../context/Context';

import { Grid } from '@mui/material';
import Pagetitle from '../components/texts/Pagetitle';
import Formfieldhead from '../components/texts/Formfieldhead';
import Selectbox from '../components/input/Selectbox';
import Multiselectbox from '../components/input/Multiselectbox';
import Groupselectbox from '../components/input/Groupselectbox';
import Searchandselect from '../components/input/Searchandselect';
import Searchandselectcat from '../components/input/Searchandselectcat';
import Searchandmultiselect from '../components/input/Searchandmultiselect';
import Pagediv from '../components/section/Pagediv';

const Selects = () => {
    const { setnavoptions } = useContext(Context);


    useEffect(() => {
        setnavoptions('selects');//this is for active sidebar
    }, []);
    return (
        <div>
            <Pagetitle title="Select Options" />

            <Formfieldhead title="Select Head" />
            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} md={3}>
                    <Selectbox Name="Basic" Type="error" Helpertext="" Options={['option 1', 'option 2', 'option 3']} FullWidth />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Selectbox Name="Small" Type="error" Helpertext="" Options={['option 1', 'option 2', 'option 3']} FullWidth Size="small" />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Selectbox Name="Disabled" Type="error" Helpertext="Disabled" Options={['option 1', 'option 2', 'option 3']} FullWidth Size="small" Disabled />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Selectbox Name="Error" Type="error" Helpertext="Error" Options={['option 1', 'option 2', 'option 3']} FullWidth Size="small" Errorval="⚠️  - " Error />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Selectbox Name="Read Only" Type="error" Helpertext="" Options={['Read 1', 'Read 2', 'Read 3']} FullWidth Size="small" InputProps={{ readOnly: true }} />
                </Grid>

            </Grid>

            <Pagediv />

            <Formfieldhead title="Multi Select" />
            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} md={3}>
                    <Multiselectbox
                        Type="basic"
                        Name="Default"
                        Options={[
                            'Oliver Hansen',
                            'Van Henry',
                            'April Tucker',
                            'Kelly Snyder']}
                        FullWidth Size="small" />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Multiselectbox
                        Type="check"
                        Name="Tag"
                        Options={[
                            'Oliver Hansen',
                            'Van Henry',
                            'April Tucker',
                            'Kelly Snyder']}
                        FullWidth Size="small" />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Multiselectbox
                        Type="chip"
                        Name="Chip"
                        Options={[
                            'Oliver Hansen',
                            'Van Henry',
                            'April Tucker',
                            'Kelly Snyder']}
                        FullWidth Size="small" />
                </Grid>
            </Grid>

            <Pagediv />

            <Formfieldhead title="Group Options" />
            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} md={3}>
                    <Groupselectbox Name="Grouping" FullWidth Size="small"
                        Options={[
                            {
                                "category": "Group 1",
                                "options": ["option 4", "option 7"]
                            },
                            {
                                "category": "Group 2",
                                "options": ["option 3", "option 4"]
                            }
                        ]}
                    />
                </Grid>
            </Grid>

            <Pagediv />

            <Formfieldhead title="Search And Select" />
            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item xs={6} md={3}>
                    <Searchandselect Name="Basic" Options={["Option 1","The Option 2", "A option"]} FullWidth Size="small" />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Searchandselectcat Name="Category" Options={[{"title":"Option 1"},{"title":"Option 2"},{"title":"Option 3"},{"title":"A Option 1"},{"title":"The Option 1"}]} FullWidth Size="small" />
                </Grid>

                <Grid item xs={6} md={3}>
                    <Searchandmultiselect Name="Search and Multiselect" Options={[{"title":"Option 1"},{"title":"Option 2"},{"title":"Option 3"},{"title":"A Option 1"},{"title":"The Option 1"}]} FullWidth Size="small" />
                </Grid>

            </Grid>

        </div>
    );
}

export default Selects;
