import React from 'react';

const Pagediv = () => {
    return (
        <>
            <div className='pagediv'></div>
        </>
    );
}

export default Pagediv;
