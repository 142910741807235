import React, { useContext, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";

import PreLoader from './pages/PreLoader';
import Body from './Body';
import Home from './web/Home';
import Dashboard from './pages/Dashboard';
import Bill from './pages/Bill';
import Selects from './pages/Selects';
import Buttons from './pages/Buttons';
import Loaders from './pages/Loaders';
import Radios from './pages/Radios';
import Tables from './pages/Tables';
import Error from './pages/Error';
import Login from './Login/Login';
import Forgot from './Login/Forgot';

// masters
import Classes from './pages/masters/classes/Classes';

import { useState } from 'react';

const SubRoutes = () => {
    // const { setlogin } = useContext(Context);
    const [login, setlogin] = useState(false);

    const logsetting = ()=>{
        let login = localStorage.getItem("login");
        if(login === "not"){
            setlogin(false);
        }else{
            console.log(login)
            setlogin(true);
        }
    }
    useEffect(()=>{
        logsetting();
    },[]);
    return (
        <Routes>
            {login === true ?
                <Route path="/" element={<Body />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/bill" element={<Bill />} />
                    <Route path="/selects" element={<Selects />} />
                    <Route path="/buttons" element={<Buttons />} />
                    <Route path="/loaders" element={<Loaders />} />
                    <Route path="/radios" element={<Radios />} />
                    <Route path="/tables" element={<Tables />} />
                    {/* masters */}
                    <Route path="/classes" element={<Classes />} />
                    {/* Error */}
                    <Route path="/*" element={<Error />} />
                </Route>
                :
                <Route path="/">
                    <Route path="/" element={<PreLoader />} />
                    <Route path="/Home" element={<Home />} />
                    <Route path="/Admin" element={<Login />} />
                    <Route path="/Forgot" element={<Forgot />} />
                    <Route path="/*" element={<Error />} />
                </Route>
            }

        </Routes>
    );
}

export default SubRoutes;
