import React from 'react';
import { Link, useNavigate } from "react-router-dom";

// css
import '../../../css/web/header.css';

// public imports
import Logo from '../../../public/web/logo.png';

// Components
import ButtonOutline from '../components/buttons/ButtonOutline';

const HeaderWeb = () => {
    return (
        <header className='web-header'>
            <div className='flex-align space-between'>
                <div>
                    <img className='web-logo' src={Logo} alt="Aanant Shiksha" />
                </div>
                <div>
                    <Link className='head-menu' to={"/"}>Features</Link>
                    <Link className='head-menu' to={"/"}>About us</Link>
                    <Link className='head-menu' to={"/"}>How it works</Link>
                </div>
                <div>
                    <ButtonOutline value="Contact" bg="tr" />
                </div>
            </div>
        </header>
    );
}

export default HeaderWeb;
