import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineDashboard, MdOutlineInput, MdOutlineSelectAll, MdOutlineDescription, MdLabelImportantOutline, MdOutlineSmartButton, MdOutlineNextPlan, MdTaskAlt, MdOutlineTableView } from "react-icons/md";
import Arrow from '../../../public/image/icons/arrow-left-w.svg';
import Context from '../../../context/Context';

const Sidebarlinks = () => {
    const { navoptions, sidetoggle, setsidetoggle, navdrop, setnavdrop, vheight, setnameshow } = useContext(Context);
    // console.log(vheight);
    const setdrop = (l) => {
        if (sidetoggle === false) {
            setsidetoggle(true);
            setTimeout(() => {
                setnavdrop(l);
                setnameshow(true);
            }, 300);
        } else {
            if (navdrop === '') {
                setnavdrop(l);
            } else {
                if (l === navdrop) {
                    setnavdrop('');
                } else {
                    setnavdrop(l);
                }
            }
        }
    }
    return (
        <div className='sidebar-links' style={{ "height": `${vheight - 118}px` }}>


            <div className='side-l'>
                <Link to={'/dashboard'}>
                    <div className={navoptions === 'dashboard' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdOutlineDashboard className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Dashboard</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='side-l'>
                <div className={navoptions === 'masters' ? 'side-link cp side-active' : 'side-link cp'} onClick={() => { setdrop('masters') }} >
                    <div className='flex-align space-between'>
                        <div className='flex-align'>
                            <MdLabelImportantOutline className='side-link-icon' />
                            <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Masters</p>
                        </div>
                        <div className={sidetoggle === false ? 'hide' : ''}><img className={navdrop === 'masters' ? 'side-arrow-active' : 'side-arrow'} src={Arrow} alt='arrow' /></div>
                    </div>
                    {/* options */}
                    <div className={navdrop === 'masters' ? 'side-options-active' : 'side-options'}>
                        <div className='side-option'>
                            <Link to={'/classes'}>
                                <p className='side-option-text'>Classes</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Courses</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Subjects</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Organization Name</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Banners</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Video Lachurs</p>
                            </Link>
                        </div>
                    </div>
                    {/* options ends */}
                </div>
            </div>

            <div className='side-l'>
                <div className={navoptions === 'invoice' ? 'side-link cp side-active' : 'side-link cp'} onClick={() => { setdrop('invoice') }} >
                    <div className='flex-align space-between'>
                        <div className='flex-align'>
                            <MdOutlineDescription className='side-link-icon' />
                            <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Invoice</p>
                        </div>
                        <div className={sidetoggle === false ? 'hide' : ''}><img className={navdrop === 'invoice' ? 'side-arrow-active' : 'side-arrow'} src={Arrow} alt='arrow' /></div>
                    </div>
                    {/* options */}
                    <div className={navdrop === 'invoice' ? 'side-options-active' : 'side-options'}>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Option 1</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Option 2</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Option 3</p>
                            </Link>
                        </div>
                    </div>
                    {/* options ends */}
                </div>
            </div>

            <div className='side-l'>
                <div className={navoptions === 'invoice2' ? 'side-link cp side-active' : 'side-link cp'} onClick={() => { setdrop('invoice2') }} >
                    <div className='flex-align space-between'>
                        <div className='flex-align'>
                            <MdOutlineDescription className='side-link-icon' />
                            <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Invoice 2</p>
                        </div>
                        <div className={sidetoggle === false ? 'hide' : ''}><img className={navdrop === 'invoice2' ? 'side-arrow-active' : 'side-arrow'} src={Arrow} alt='arrow' /></div>
                    </div>
                    {/* options */}
                    <div className={navdrop === 'invoice2' ? 'side-options-active' : 'side-options'}>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Option 1</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Option 2</p>
                            </Link>
                        </div>
                        <div className='side-option'>
                            <Link to={'#'}>
                                <p className='side-option-text'>Option 3</p>
                            </Link>
                        </div>
                    </div>
                    {/* options ends */}
                </div>
            </div>

            <div className='side-l'>
                <Link to={'/bill'}>
                    <div className={navoptions === 'bill' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdOutlineInput className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Input Fields</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='side-l'>
                <Link to={'/selects'}>
                    <div className={navoptions === 'selects' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdOutlineSelectAll className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Select</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='side-l'>
                <Link to={'/buttons'}>
                    <div className={navoptions === 'buttons' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdOutlineSmartButton className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Button</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='side-l'>
                <Link to={'/radios'}>
                    <div className={navoptions === 'radios' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdTaskAlt className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Radios & Check</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='side-l'>
                <Link to={'/loaders'}>
                    <div className={navoptions === 'loaders' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdOutlineNextPlan className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Loaders</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='side-l'>
                <Link to={'/tables'}>
                    <div className={navoptions === 'tables' ? 'side-link side-active' : 'side-link'}>
                        <div className='flex-align space-between'>
                            <div className='flex-align'>
                                <MdOutlineTableView className='side-link-icon' />
                                <p className={sidetoggle === false ? 'hide' : 'side-link-text'}>Tables</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

        </div>
    );
}

export default Sidebarlinks;
