import React from 'react';

import { PieChart } from '@mui/x-charts/PieChart';

const Pichart = (props) => {
    return (
        <>
            <PieChart
                series={[
                    {
                        data: props.Data,
                    },
                ]}
                height={300}
            />
        </>
    );
}

export default Pichart;
